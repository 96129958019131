<template>
  <v-container class="align-stretch layout-container">
    <BaseHeader
      title="Ihre KI-Schmiede"
      :style-variant="11"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>Wir machen es Ihnen einfach: Mit nur einem Klick erzeugen wir die richtigen Texte für Ihre Zwecke.</p>
      </template>
    </BaseHeader>

    <v-card outlined>
      <v-card-title>
        Texte für Ihre (gebuchten) Köder
        <v-badge
          content="BETA"
          color="orange"
          class="ml-4"
        />
      </v-card-title>
      <v-card-text>
        <p>
          Um es Ihnen so einfach wie nur irgend möglich zu machen, haben wir Ihnen bereits den größten Teil der Arbeit abgenommen.
          Wenn Sie einen Ihrer Köder (Ratgeber, Situationsanalyse, etc.) bewerben möchten, so reicht ein einziger Klick.
        </p>
        <p>
          Aktuell können Sie folgende Textarten generieren:
          <ul>
            <li>Facebook Post</li>
            <li>Instagram Post</li>
            <template v-if="!isLight">
              <li>LinkedIn Post</li>
              <li>Facebook Ads</li>
              <li>Kundenanschreiben</li>
              <li>Text für Website</li>
              <li>Google Business Profil Newspost</li>
              <li>Google Business Profil Produktbeschreibung</li>
              <li>Blog Posts (geplant, aktuell noch nicht verfügbar)</li>
            </template>
          </ul>
        </p>
        <p>
          Klicken Sie dazu einfach im Bereich <router-link to="/landingpages">
            Landingpages &amp; Köder
          </router-link>
          beim gewünschten Köder auf "mehr". In der Detailansicht benötigt es dann nur noch einen Klick.
        </p>
      </v-card-text>
    </v-card>

    <Feature :feature-slug="featureNames.ASKAI_ASSISTENT">
      <v-card
        class="mt-4"
        outlined
      >
        <v-card-title>
          Ihr KI Assistent
          <v-badge
            content="BETA"
            color="orange"
            class="ml-4"
          />
        </v-card-title>
        <v-card-text>
          <p>
            Trainiert mit dem gesamten BOTTIMMO-Wissen aus Ihren Ratgebern und Checklisten,
            sowie allen Ratgebern, die wir für Sie als Makler geschrieben haben.
          </p>
          <p>
            Sie können z.B. fragen: "Was muss ich bei einer PV Anlage beachten?" oder "Wie betreibe ich gutes Farming?". <br>
            Klicken Sie auf das "+" unter der Antwort, um Referenzen angezeigt zu bekommen. Diese verweisen jeweils auf die Ratgeber.<br>
            Probieren Sie es einfach aus und hinterlassen uns gerne Feedback.
          </p>

          <AskAi id="kOrFVyoS4cjhDwTmWuyHDT1A0kOuXc" />

          <v-alert
            type="info"
            icon="mdi-lightbulb-on-20"
            class="mt-4 body-2"
            text
          >
            Wir stellen Ihnen hier eine erste <strong>BETA-Version</strong> unseres KI-Assistenten zur Verfügung.
            Daher freuen wir uns über jedes Feedback.<br>
            <v-btn
              color="secondary"
              class="mt-4"
              href="https://survey.zohopublic.eu/zs/5ADH3b"
              target="_blank"
              rel="noopener noreferrer"
              small
              outlined
            >
              Jetzt Feedback geben …
            </v-btn>
          </v-alert>
        </v-card-text>
      </v-card>
    </Feature>
  </v-container>
</template>

<script>
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from '@/modules/performanceReport/HeaderDesign.vue'
import AskAi from '@/components/AskAi.vue'
import { isLightUser } from '@/lib/productTypes'

export default {
  components: {
    BaseHeader,
    HeaderDesign,
    Feature,
    AskAi
  },

  data () {
    return {
      featureNames
    }
  },
  computed: {
    isLight () {
      return isLightUser(this.$auth.user)
    }
  }
}
</script>
