<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 142.7 91"
    enable-background="new 0 0 142.7 91"
    xml:space="preserve"
  >
    <g id="fade-circles">
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="0"
        y1="45.524"
        x2="91.039"
        y2="45.524"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_3_)"
        stroke-width="6"
        stroke-miterlimit="10"
        d="M88,45.5C88,22.1,69,3,45.5,3v0C22,3,3,22,3,45.5h0C3,69,22,88,45.5,88v0C69,88,88,69,88,45.5L88,45.5z"
      />
      <linearGradient
        id="SVGID_4_"
        gradientUnits="userSpaceOnUse"
        x1="9.5708"
        y1="45.524"
        x2="99.6098"
        y2="45.524"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_4_)"
        stroke-width="5"
        stroke-miterlimit="10"
        d="M97.1,45.5C97.1,22.1,78.1,3,54.6,3v0C31.1,3,12.1,22,12.1,45.5h0C12.1,69,31.1,88,54.6,88v0C78.1,88,97.1,69,97.1,45.5L97.1,45.5z"
      />
      <linearGradient
        id="SVGID_5_"
        gradientUnits="userSpaceOnUse"
        x1="19.1417"
        y1="45.524"
        x2="108.1807"
        y2="45.524"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_5_)"
        stroke-width="4"
        stroke-miterlimit="10"
        d="M106.2,45.5c0-23.5-19-42.5-42.5-42.5v0C40.2,3,21.2,22,21.2,45.5h0C21.1,69,40.2,88,63.6,88v0C87.1,88,106.2,69,106.2,45.5L106.2,45.5z"
      />
      <linearGradient
        id="SVGID_6_"
        gradientUnits="userSpaceOnUse"
        x1="28.7125"
        y1="45.524"
        x2="116.7515"
        y2="45.524"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_6_)"
        stroke-width="3"
        stroke-miterlimit="10"
        d="M115.3,45.5c0-23.5-19-42.5-42.5-42.5v0C49.3,3,30.2,22,30.2,45.5h0C30.2,69,49.2,88,72.7,88v0C96.2,88,115.2,69,115.3,45.5L115.3,45.5z"
      />
      <linearGradient
        id="SVGID_7_"
        gradientUnits="userSpaceOnUse"
        x1="38.2834"
        y1="45.524"
        x2="125.3224"
        y2="45.524"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_7_)"
        stroke-width="2"
        stroke-miterlimit="10"
        d="M124.3,45.5c0-23.5-19-42.5-42.5-42.5v0C58.3,3,39.3,22,39.3,45.5h0C39.3,69,58.3,88,81.8,88v0C105.3,88,124.3,69,124.3,45.5L124.3,45.5z"
      />
      <linearGradient
        id="SVGID_8_"
        gradientUnits="userSpaceOnUse"
        x1="47.8542"
        y1="45.524"
        x2="133.8932"
        y2="45.524"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_8_)"
        stroke-miterlimit="10"
        d="M133.4,45.5c0-23.5-19-42.5-42.5-42.5v0C67.4,3,48.4,22,48.4,45.5h0C48.4,69,67.4,88,90.9,88v0C114.3,88,133.4,69,133.4,45.5L133.4,45.5z"
      />
      <linearGradient
        id="SVGID_9_"
        gradientUnits="userSpaceOnUse"
        x1="57.1751"
        y1="45.524"
        x2="142.7142"
        y2="45.524"
      >
        <stop
          offset="0"
          :style="`stop-color:${strokeStart}`"
        />
        <stop
          offset="1"
          :style="`stop-color:${strokeStop}`"
        />
      </linearGradient>
      <path
        fill="none"
        stroke="url(#SVGID_9_)"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M142.5,45.5c0-23.5-19-42.5-42.5-42.5v0C76.5,3,57.4,22,57.4,45.5h0C57.4,69,76.5,88,99.9,88v0C123.4,88,142.5,69,142.5,45.5L142.5,45.5z"
      />
    </g>
  </svg>
</template>

<script>
import svg from '@/mixins/svg'
export default {
  mixins: [svg]
}
</script>
