<template>
  <div>
    <FadeCircles
      class="banner-design-elements"
      width="400px"
      style="right: 20px; top:-10px; opacity: 0.1"
    />
    <Leaf
      class="banner-design-elements"
      width="130px"
      style="right: 90px; top:50px; opacity: 0.4"
      stroke
      :stroke-width="1"
    />
    <SingleCircle
      class="banner-design-elements"
      width="50px"
      style="right: 20px; top:10px; opacity: 0.6"
      stroke
    />

    <SingleCircle
      class="banner-design-elements"
      width="50px"
      style="right: 10px; top:15px; opacity: 0.3"
      fill
    />
  </div>
</template>

<script>
import Leaf from '@/components/design-elements/leaf.vue'
import FadeCircles from '@/components/design-elements/fadeCircles.vue'
import SingleCircle from '@/components/design-elements/singleCircle.vue'

export default {
  components: {
    Leaf,
    FadeCircles,
    SingleCircle
  }
}
</script>
