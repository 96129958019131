<template>
  <div
    class="askai-frame-embed"
    :data-id="id"
  />
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      initialized: false
    }
  },

  mounted () {
    if (!this.initialized) {
      const script = document.createElement('script')
      script.setAttribute('src', 'https://myaskai.com/embed-js-min')
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('defer', true)
      document.head.appendChild(script)
      this.initialized = true
    }
  }
}
</script>
